#rcDialogTitle0 {
  color: rgb(68, 106, 170);
  font-weight: bold;
}

.ant-modal-header {
  border: none;
}

.ant-modal-footer {
  border: none;
  text-align: center;
}

.ant-modal-footer .ant-btn {
  width: 100px;
}

.ant-modal-footer .ant-btn-primary {
  color: #fff;
  background-color: rgb(68, 106, 170);
  border-color: rgb(68, 106, 170);
}
