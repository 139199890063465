body {
  margin: 0;
  font-family: 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo, Osaka', 'ＭＳ Ｐゴシック',
    'MS PGothic', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loginForm {
  -webkit-box-shadow: 1px 10px 73px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 1px 10px 73px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 1px 10px 73px 0px rgba(0, 0, 0, 0.45);
}

:root {
  --theme-color: rgb(68, 106, 170);
}

h2 {
  color: var(--theme-color) !important;
  font-weight: bold;
}
